import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useGameLikeWorkspace } from '../../../src/components/Game/GameCenter';
import { DefaultGamePackCardBadges } from '../../../src/components/Game/GamePack/GamePackCard';
import { TagUtils } from '../../../src/components/Tagging/utils';
import { GamePackRow } from '../../../src/pages/GamePack/Collections';
import { GamePackCardBottomAccessory } from '../../../src/pages/GamePack/GamePackCardBottomAccessory';
import { type Tag } from '../../../src/types';
import { type GamePack } from '../../../src/types/game';
import { getGridStyle } from '../../../src/utils/css';
import {
  GamePackCard,
  useHydratedNumPerRow,
} from '../GamePack/GamePackCollection';

export function AdminPublicLibraryGamePackCard(props: { pack: GamePack }) {
  const { pack } = props;

  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  return (
    <GamePackCard
      gamePack={pack}
      onClick={() => setActiveGamePack(pack)}
      badges={<DefaultGamePackCardBadges gamePack={pack} />}
      bottomAccessory={<GamePackCardBottomAccessory pack={pack} />}
      styles={{
        size: 'w-full',
      }}
      showVersion={true}
    />
  );
}

export function AdminPublicLibraryGamePackList(props: { packs: GamePack[] }) {
  const { packs } = props;

  const numPerRow = useHydratedNumPerRow();

  return (
    <div
      className={`w-full grid gap-y-12 gap-x-3 pt-4 ${
        numPerRow > 0 ? 'visible' : 'invisible'
      }`}
      style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
    >
      {packs.map((p) => (
        <AdminPublicLibraryGamePackCard key={p.id} pack={p} />
      ))}
    </div>
  );
}

export function AdminPublicLibraryGamePackRow(props: {
  pageName: EnumsPageName;
  tag: Tag;
}) {
  const { pageName, tag } = props;

  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const navigate = useNavigate();
  const handleClickTag = (tag: Tag) => {
    navigate(
      $path('/admin/public-library/:name/tags/:slug', {
        name: pageName,
        slug: tag.slug,
      })
    );
  };

  return (
    <GamePackRow
      tag={tag}
      personalized
      showVersion
      showHeader={
        pageName !== EnumsPageName.PageNameLiveOtp &&
        pageName !== EnumsPageName.PageNameLiveLoggedIn
      }
      showReorderButton={!TagUtils.IsVirtualTag(tag.slug)}
      onViewAll={handleClickTag}
      onClickGamePack={(pack) => setActiveGamePack(pack)}
    />
  );
}
