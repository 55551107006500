import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useState } from 'react';
import { $path } from 'remix-routes';

import {
  type GameLikeFilterOptions,
  toGameLikeFilters,
} from '../../app/components/GamePack/GamePackFilters';
import {
  AdminPublicLibraryLayout,
  useAdminPublicLibraryData,
} from '../../app/components/PublicLibrary/AdminPublicLibraryLayout';
import { AdminPublicLibraryGamePackList } from '../../app/components/PublicLibrary/AdminPublicLibraryPacks';
import { PublicLibraryBreadcrumbs } from '../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import {
  encodeFilterOptions,
  parseFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysAllLoggedIn,
} from '../../app/components/PublicLibrary/PublicLibraryFilter';
import { PageManagerUtils } from '../components/PageManager/utils';
import { apiService } from '../services/api-service';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { name } = action.params;
  if (!PageManagerUtils.IsPageName(name)) {
    throw new Error(`Invalid page name: ${name}`);
  }
  const params = new URL(action.request.url).searchParams;
  const filterOptions = parseFilterOptions(params);
  const q = params.get('q') || '';

  const packs = await apiService.gamePack
    .searchGamePacks(q, {
      filterParams: toGameLikeFilters(filterOptions),
      primeOnly: true,
      scope: name,
    })
    .next();

  return {
    q: params.get('q'),
    filterOptions,
    packs,
  };
}

export function Component() {
  const { pageName } = useAdminPublicLibraryData();
  const { filterOptions, packs } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const handleApply = (options: Partial<GameLikeFilterOptions> | null) => {
    if (!options) return;

    const params = encodeFilterOptions(options, {
      init: new URLSearchParams(window.location.search),
    });
    navigate({
      search: params.toString(),
    });
  };

  const [filters, setFilters] =
    useState<Partial<GameLikeFilterOptions>>(filterOptions);

  return (
    <AdminPublicLibraryLayout>
      <div className='w-full p-10 flex flex-col gap-10'>
        <PublicLibraryBreadcrumbs
          items={[
            {
              label: 'Explore',
              kind: 'link',
              to: $path('/admin/public-library/:name', {
                name: pageName,
              }),
            },
            { label: 'Matched Experiences', kind: 'noop' },
          ]}
        />

        <div className='w-full flex justify-center'>
          <PublicLibraryFilter
            showMenuKeys={PublicLibraryFilterKeysAllLoggedIn}
            filterOptions={filters}
            onChange={setFilters}
            onApply={handleApply}
          />
        </div>

        {packs.length > 0 ? (
          <AdminPublicLibraryGamePackList packs={packs} />
        ) : (
          <div className='text-secondary text-center'>
            No matching Game Packs found
          </div>
        )}
      </div>
    </AdminPublicLibraryLayout>
  );
}
